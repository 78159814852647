<template>
  <v-dialog
    v-model="showlegalModal"
    max-width="550"
    transition="dialog-bottom-transition"
    @click:outside="$emit('closelegalModal')"
  >
    <v-container class="background rounded-lg">
      <v-row cols="12" sm="6" md="4" justify="end">
        <v-icon
          class="ma-0 pa-0 mt-2 mr-2"
          color="primary"
          @click="$emit('closelegalModal')"
          >mdi-close</v-icon
        ></v-row
      >
      <v-row
        class="primary--text text-h5 ma-0 pa-0 ml-2 mt-2 font-weight-semibold"
        >{{
          type == "TermsAndConditions" ? $t("terms.title") : $t("privacy.title")
        }}</v-row
      >
      <v-row class="secondary--text" v-if="type == 'TermsAndConditions'">
        <div id="terms" class="px-5"></div>
      </v-row>
      <v-row class="secondary--text" v-if="type == 'PrivacyPolicy'">
        <div id="privacy" class="px-5"></div>
      </v-row>
      <v-row class="mx-2 my-8" justify="center">
        <v-col cols="6" class="pa-0 mt-2 text-center font-weight-medium">
          <div>
            <v-btn
              @click="$emit('closelegalModal')"
              color="secondary"
              class="text-capitalize"
              style="font-size: 16px"
              large
              rounded
            >
              {{ $t("operation.understood") }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-dialog>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      dialog: true,
    };
  },
  props: {
    type: {
      required: true,
    },
    showlegalModal: {
      required: true,
      type: Boolean,
    },
  },
  watch: {
    showlegalModal() {
      if (this.showlegalModal == true)
        setTimeout(() => {
          {
            if (this.type == "PrivacyPolicy") {
              document.getElementById("privacy").innerHTML = "";
              this.privacyPolicy.map((term) => {
                this.createList(term, "privacy");
              });
            } else if (this.type == "TermsAndConditions") {
              document.getElementById("terms").innerHTML = "";
              this.termsAndConditions.map((term) => {
                this.createList(term, "terms");
              });
            }
          }
        }, 0);
    },
  },
  computed: {
    ...mapGetters({ termsAndConditions: "legal/termsAndConditions" }),
    ...mapGetters({ privacyPolicy: "legal/privacyPolicy" }),
  },
  mounted() {
    this.getTermsAndConditions();
    this.getprivacyPolicy();
  },
  methods: {
    ...mapActions({ getTermsAndConditions: "legal/getTermsAndConditions" }),
    ...mapActions({ getprivacyPolicy: "legal/getprivacyPolicy" }),
    createList(term, id) {
      let div = document.createElement("div");
      div.className = "ma-0 pa-0";
      let h3 = document.createElement("h3");
      h3.innerText = term.name;
      let p = document.createElement("p");
      p.innerText = term.text;
      div.appendChild(h3);
      div.appendChild(p);
      document.getElementById(id).appendChild(div);
    },
  },
};
</script>
<style scoped>
.background {
  background-color: white;
  background-image: url("~@/assets/apotalent/background.svg");
  background-repeat: no-repeat;
  background-position: bottom;
}
.customButton {
  box-shadow: none;
  text-transform: none;
  font-size: 16px;
}
</style>
