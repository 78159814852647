<template>
  <v-container fluid>
    <div class="mx-4">
      <v-form ref="form" v-model="formValid" lazy-validation>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <h3 class="mt-4 font-weight-black">
              {{ $t("register.personal_data") }}
            </h3>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-row justify="center" style="width: 100%">
            <v-col cols="12" sm="8" md="4">
              <v-subheader class="pa-0 ma-0 text-caption primary--text"
                >
                {{ $t("field.full_name") }}
                <span class="red--text text-subtitle-2">*</span>
              </v-subheader>
              
            </v-col>
          </v-row>
          <v-col cols="4" sm="2" md="1" class="pa-0">
            <v-select
              :items="computedTitles"
              v-model="fields.gender"
              hide-details
              outlined
              item-text="name"
              item-value="id"
            >
            </v-select>
          </v-col>
          <v-col cols="8" sm="6" md="3" class="pa-0">
            <v-text-field
              :label="$t('field.full_name')"
              :rules="required"
              hide-details="auto"
              outlined
              v-model="fields.full_name"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <v-subheader class="pa-0 ma-0 text-caption primary--text"
              >
              {{ $t("field.contact_phone_number") }}
              <span class="red--text text-subtitle-2">*</span>
            </v-subheader>
            <vue-tel-input
              :requiredPass="requiredDefault"
              :phoneNumber="fields.phone_number"
              @updatePhoneNumber="updatePhoneNumber"
              @updateFormValid="updateFormValid"
              @updateCountry="updateCountry"
            />
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <v-subheader class="pa-0 text-caption primary--text"
              >{{ $t("field.address") }} <span class="red--text text-subtitle-2">*</span
              ></v-subheader>
              
            <vuetify-google-autocomplete
              ref="address"
              id="map"
              classname="form-control"
              @change="validateAddress('address', $event)"
              :label="$t('field.address_placeholder')"
              outlined
              tabindex="1"
              :hide-details="false"
              validate-on-blur
              :rules="address_validator"
              v-on:placechanged="getAddressData"
              country="es"
            >
            </vuetify-google-autocomplete>
          </v-col>
        </v-row>
        
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <v-subheader class="pa-0 text-caption primary--text">
              
              {{ $t("field.birthday") }}
              <span class="red--text text-subtitle-2">*</span>
              </v-subheader>
            <div
              class="d-flex"
              justify="start"
              :style="isMobile ? 'width: 100%' : 'width: 60%'"
            >
              <v-text-field
                name="input-10-1"
                type="number"
                :label="$t('field.day')"
                hide-details="auto"
                outlined
                id="day"
                :error="hasError"
                v-model="day"
                tabindex="0"
                @blur="validateBirthDay(1)"
                v-mask="'##'"
                placeholder="DD"
              >
              </v-text-field>
              <v-text-field
                name="input-10-2"
                :label="$t('field.month')"
                class="ml-2"
                type="number"
                id="month"
                :error="hasError"
                hide-details="auto"
                outlined
                tabindex="0"
                @blur="validateBirthDay(2)"
                v-model="month"
                v-mask="'##'"
                placeholder="MM"
              >
              </v-text-field>
              <v-text-field
                name="input-10-3"
                type="number"
                :label="$t('field.year')"
                hide-details="auto"
                :error="hasError"
                class="ml-2"
                id="year"
                outlined
                tabindex="0"
                @blur="validateBirthDay(3)"
                v-model="year"
                v-mask="'####'"
                placeholder="YYYY"
              >
              </v-text-field>
            </div>
            <div v-if="hasError" class="v-text-field__details wrap-error m-0">
              <div
                class="v-messages theme--light error--text wrap-error"
                role="alert"
              >
                <div class="v-messages__wrapper wrap-error">
                  <div class="v-messages__message wrap-error mt-2 ml-3">
                    {{ errorMessage }}
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
        <!-- <v-row class="pa-0 ma-0" justify-sm="center">
          <v-radio-group
            class="pa-0 ma-0 mt-4"
            v-model="fields.gender"
            row
            :rules="required"
          >
            <v-radio :value="2">
              <template v-slot:label>
                <div class="primary--text text-subtitle-1 font-weight-normal">
                  {{ $t("gender.im_woman") }}
                </div>
              </template></v-radio
            >
            <v-radio :value="1">
              <template v-slot:label>
                <div class="primary--text text-subtitle-1 font-weight-normal">
                  {{ $t("gender.im_men") }}
                </div>
              </template></v-radio
            >
          </v-radio-group>
        </v-row> -->
      </v-form>
    </div>
    <v-row class="mx-4 mt-2 mb-8" justify="end" justify-sm="center">
      <v-col cols="6" sm="4" md="3" class="pa-0 mt-2 mr-0 text-right">
        <div>
          <v-btn
            color="secondary"
            block
            large
            rounded
            @click="updateStep"
            :disabled="!formValid || !phoneValid || hasError"
          >
            {{ $t("operation.next") }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import VuetifyGoogleAutocomplete from "vuetify-google-autocomplete";
import VueTelInput from "@/components/shared/VueTelInput.vue";
export default {
  components: {
    VuetifyGoogleAutocomplete,
    VueTelInput,
  },
  data() {
    return {
      requiredDefault: true,
      show1: false,
      cumulativeBlur: 0,
      hasError: false,
      errorMessage: this.$i18n.t("validation.required"),
      formValid: false,
      phoneValid: false,
      country_code: "",
      phone_number: null,
      phone_number_format: null,
      country: null,
      tAddress: null,
      titles: [
        {
          name: this.$i18n.t("field.sr"),
          id: 1,
        },
        {
          name: this.$i18n.t("field.sra"),
          id: 2,
        },
      ],
      day: null,
      month: null,
      year: null,
      fields: {
        full_name: null,
        phone_number: null,
        address: {
          raw: null,
          route: null,
          street_number: null,
          formatted: null,
          latitude: null,
          longitude: null,
          locality: {
            postal_code: null,
            name: null,
            state: {
              name: null,
              code: null,
              country: {
                name: null,
                code: null,
              },
            },
          },
        },
        postalCode: null,
        municipality: null,
        active_search:true,
        province: null,
        country: null,
        birthday_date: null,
        gender: 1,
      },
      required: [(v) => !!v || this.$i18n.t("validation.required")],
      mobile_number: [
        (v) => !!v || this.$i18n.t("validation.required"),
        (v) =>
          /^\+?[0-9]{1,3}[\s-]?[0-9]{8,12}$/.test(v) ||
          this.$i18n.t("validation.telephone"),
      ],
      birthday_date: [
        (v) => !!v || this.$i18n.t("validation.required"),
        (v) =>
          /(^(((0[1-9]|1[0-9]|2[0-8])[/](0[1-9]|1[012]))|((29|30|31)[/](0[13578]|1[02]))|((29|30)[/](0[4,6,9]|11)))[/](19|[2-9][0-9])\d\d$)|(^29[/]02[/](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)/.test(
            v
          ) || this.$i18n.t("validation.birthday_date"),
        (v) => this.age(v) >= 18 || this.$i18n.t("validation.adult"),
      ],
      address_validator: [
        (v) => !!v || this.$i18n.t("validation.required"),
        (v) => {
          return (
            (v &&
              this.fields.address.latitude &&
              this.fields.address.latitude != 0) ||
            this.$i18n.t("validation.address")
          );
        },
      ],
      code: [
        {
          name: "España (+34)",
          icon: "",
          code: "+34 ",
        },
      ],
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    computedTitles() {
      return this.titles;
    },
  },
  mounted() {},
  watch: {
    day() {
      this.nextFieldBirth(1);
    },
    month() {
      this.nextFieldBirth(2);
    },
    year() {
      this.nextFieldBirth(3);
    },
  },
  methods: {
    nextFieldBirth(index) {
      //let day=document.getElementById('day');
      if (index == 1) {
        if (this.day.length == 2) {
          document.getElementById("month").focus();
        }
      } else if (index == 2) {
        if (this.month.length == 2) {
          document.getElementById("year").focus();
        }
      }
    },
    validateAddress(address, formatted) {
      if (
        this.fields[address].formatted != formatted &&
        this.fields[address].latitude
      ) {
        this.fields[address] = {};
      }
    },
    validateBirthDay(moreBlur) {
      this.cumulativeBlur = this.cumulativeBlur + moreBlur;
      const birthdayField = this.day + "/" + this.month + "/" + this.year;
      if (this.day != null) {
        if (this.day.length == 1) {
          this.day = "0" + this.day;
        }
      }
      if (this.month != null) {
        if (this.month.length == 1) {
          this.month = "0" + this.month;
        }
      }

      let regex =
        /(^(((0[1-9]|1[0-9]|2[0-8])[/](0[1-9]|1[012]))|((29|30|31)[/](0[13578]|1[02]))|((29|30)[/](0[4,6,9]|11)))[/](19|[2-9][0-9])\d\d$)|(^29[/]02[/](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)/;
      if (this.cumulativeBlur >= 5) {
        if (this.day == null || this.month == null || this.year == null) {
          this.errorMessage = this.$i18n.t("validation.required");
          this.hasError = true;
        } else {
          if (birthdayField.match(regex)) {
            if (this.age(birthdayField) < 18) {
              this.errorMessage = this.$i18n.t("validation.adult");
              this.hasError = true;
            } else {
              this.hasError = false;
              this.fields.birthday_date = birthdayField;
            }
          } else {
            this.errorMessage = this.$i18n.t("validation.birthday_date");
            this.hasError = true;
          }
        }
      }
    },
    updateCountry(country) {
      this.country = "+" + country;
    },
    updatePhoneNumber(phone) {
      this.fields.phone_number = phone;
    },
    updateFormValid(valid) {
      this.phoneValid = valid;
    },
    updateStep: function () {
      let validateForm = false;
      if (this.$refs.form.validate()) {
        validateForm = true;
      }
      if (this.day == null || this.month == null || this.year == null) {
        this.hasError = true;
      } else {
        if (validateForm == true) {
          this.updateCandidateInfo();
          this.$emit("updateStep", 2);
        }
      }
    },
    updateCandidateInfo() {
      var oldData = this.fields.birthday_date;
      this.fields.birthday_date = this.formatDate(this.fields.birthday_date);
      this.formatTelephone();
      this.$emit("updateCandidateInfo", this.fields);
      this.fields.birthday_date = oldData;
    },
    validate() {
      this.$refs.form.validate();
    },
    formatDate(date) {
      return date.split("/").reverse().join("-");
    },
    getAddressData(addressData, placeResultData) {
      // changing data to the format currently in the api
      this.fields.address = {
        raw: addressData.name,
        route: addressData.route,
        street_number: addressData.street_number,
        formatted: placeResultData.formatted_address,
        latitude: addressData.latitude,
        longitude: addressData.longitude,
        locality: {
          postal_code: addressData.postal_code,
          name: addressData.locality,
          state: {
            name: placeResultData.address_components.find(
              (elm) => elm.short_name == addressData.administrative_area_level_2
            ).long_name,
            code:
              placeResultData.address_components.find(
                (elm) => elm.long_name == addressData.country
              ).short_name +
              "-" +
              addressData.administrative_area_level_2,
            country: {
              name: addressData.country,
              code: placeResultData.address_components.find(
                (elm) => elm.long_name == addressData.country
              ).short_name,
            },
          },
        },
      };
    },
    formatTelephone() {
      let phone = this.fields.phone_number;
      if (phone.startsWith("+")) {
        let tel = this.fields.phone_number;
        tel = tel.split(" ");
        let country = tel[0];
        tel.shift();
        let phone = tel.join();
        let phone2 = phone.replaceAll(",", "");
        this.fields.phone_number = country + " " + phone2;
      } else {
        let tel = this.country + this.fields.phone_number;
        tel = tel.split(" ");
        let country = tel[0];
        tel.shift();
        let phone = tel.join();
        let phone2 = phone.replaceAll(",", "");
        this.fields.phone_number = country + " " + phone2;
      }
    },
    age(date) {
      return this.$moment(this.$moment(), "DD/MM/YYYY").diff(
        this.$moment(date, "DD/MM/YYYY"),
        "years"
      );
    },
  },
};
</script>
<style scoped>
.v-select__selections .country-code {
  width: 30% !important;
  padding: 0 20px;
}
.v-input__icon .v-input__icon--append > i {
  display: none !important;
}
.v-select__selections .v-text-field--outlined {
  width: 70% !important;
}

.v-select__selections .vti__flag {
  margin-left: 0px !important;
}

.country-code .v-text-field--outlined {
  padding: 0 !important;
}

.country-code .v-text-field--outlined .-v-input__control {
  padding: 0 !important;
}

.vue-tel-input-vuetify .country_code {
  width: 200px !important;
  margin: 0 !important;
}
t-vuetify {
  width: 100% !important;
  margin: 0 !important;
}

.country_code .v-input__control .v-text-field__details {
  padding: 0 !important;
  display: none;
  color: transparent !important;
}

.country-code .v-input__icon--append {
  display: none !important;
  color: transparent !important;
}

.country-code .v-input__icon {
  display: none !important;
  color: transparent !important;
}

.country-code .v-icon {
  display: none !important;
  color: transparent !important;
}

.country-code i {
  display: none !important;
  color: transparent !important;
  width: 0 !important;
}

.country-code .v-input__append-inner {
  display: none !important;
  color: transparent !important;
}

i::before {
  content: "";
  display: none !important;
}
i::after {
  content: "";
  display: none !important;
  content: none;
}

.country-code .mdi:before,
.mdi-set {
  font-size: 0px !important;
  text-decoration: none;
  content: none;
  display: none;
}
</style>
