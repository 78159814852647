<template>
  <div>
    <legal
      @closelegalModal="closelegalModal"
      :showlegalModal.sync="legalModal"
      :type="legalType"
    />
    <div class="mx-4">
      <v-form ref="form" v-model="formValid" lazy-validation>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <h3 class="mt-4 mb-2 pa-0 font-weight-bold">
              {{ $t("register.other_data") }} 
            </h3>
            <h5 class="black--text font-weight-light"> {{ $t("register.quote") }} </h5>
            
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="10" sm="8" md="4" class="pa-0 ma-0">
            <v-subheader class="pa-0 ma-0 text-caption primary--text"
              >{{ $t("register.you_know_us_from") }}  
            </v-subheader>
            <v-select
          v-model="candidate.referrer_name"
          :items="referrerSocial"
          item-text="name"
          item-value="id"
          :label="$t('register.you_know_us_from')"
          outlined
          single-line
        ></v-select>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="10" sm="8" md="4" class="pa-0 ma-0">
            <v-subheader class="pa-0 ma-0 text-caption primary--text"
              >{{ $t("cv.add_cv") }} 
            </v-subheader>
            <v-row class="pa-0 ma-0">
              <v-col cols="8" sm="8" md="4" class="pa-0">
                <v-btn
                  color="secondary"
                  block
                  outlined
                  large
                  rounded
                  @click="addCv"
                  style="text-transform: none"
                >
                  {{ $t("cv.add") }} 
                  <v-icon right dark> mdi-paperclip </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row
              class="pa-0 ma-0 my-2 mt-2"
              justify="center"
              justify-sm="start"
            >
              <v-col cols="10" sm="8" md="4" class="pa-0">
                <v-file-input
                  small-chips
                  show-size
                  :class="candidate.curriculum != null ? '' : 'd-none'"
                  id="cv"
                  clear-icon="mdi-delete"
                  prepend-icon=""
                  label="Curriculum"
                  truncate-length="15"
                  v-model="candidate.curriculum"
                  accept=".pdf,.doc,.docx"
                ></v-file-input>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="10" sm="8" md="4" class="pa-0 ma-0">
            <v-subheader class="pa-0 ma-0 text-caption primary--text"
              >{{ $t("other_files.add_other_files") }} 
            </v-subheader>
            <v-row class="pa-0 ma-0">
              <v-col cols="8" sm="8" md="4" class="pa-0">
                <v-btn
                  color="secondary"
                  block
                  outlined
                  large
                  rounded
                  @click="addOtherFields"
                  style="text-transform: none"
                >
                  {{ $t("other_files.add") }} 
                  <v-icon right dark> mdi-paperclip </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row
              class="pa-0 ma-0 my-2 mt-2"
              justify="center"
              justify-sm="start"
            >
              <v-col cols="10" sm="8" md="4" class="pa-0">
                <v-file-input
                  small-chips
                  show-size
                  :class="candidate.other_files != null ? '' : 'd-none'"
                  id="other"
                  clear-icon="mdi-delete"
                  prepend-icon=""
                  label="other"
                  truncate-length="15"
                  v-model="candidate.other_files"
                  accept=".jpg,.png,.jpeg,.pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                ></v-file-input>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="10" sm="8" md="4" class="pa-0 ma-0 mt-2">
            <v-subheader class="pa-0 ma-0 text-caption primary--text"
              >{{ $t("field.avatar") }} 
            </v-subheader>
            <v-row class="pa-0 ma-0 mb-2">
              <v-col cols="8" sm="8" md="4" class="pa-0">
                <v-btn
                  color="secondary"
                  class="mt-2"
                  block
                  outlined
                  large
                  rounded
                  @click="addAvatar"
                  style="text-transform: none"
                >
                  {{ $t("register.avatar") }}
                  <v-icon right dark> mdi-paperclip </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row
              class="pa-0 ma-0 my-2 mt-2"
              justify="center"
              justify-sm="start"
            >
              <v-col cols="10" sm="8" md="4" class="pa-0">
                <v-file-input
                  small-chips
                  id="avatar"
                  :label="$t('field.avatar')"
                  clear-icon="mdi-delete"
                  prepend-icon=""
                  truncate-length="15"
                  v-model="candidate.avatar"
                  :class="candidate.avatar != null ? '' : 'd-none'"
                  accept="image/*"
                ></v-file-input>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0 mt-2 ml-2">
            <span>{{ $t("video.add") }}</span> 
            <h5 class="p-2 mt-2 secondary--text">- {{ $t("video.videocv_data_1") }}</h5>
            <h5 class="p-2 mt-1 secondary--text">- {{ $t("video.videocv_data_2") }}</h5>
            <h5 class="p-2 mt-1 secondary--text">- {{ $t("video.videocv_data_3") }}</h5>
          </v-col>
        </v-row>

        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="10" sm="8" md="4" class="pa-0 ma-0">
            <v-row class="pa-0 ma-0 my-2">
              <v-col cols="8" sm="8" md="4" class="pa-0">
                <v-btn
                  color="secondary"
                  class="mt-2"
                  block
                  large
                  outlined
                  rounded
                  @click="!isMobileDevice ? openRecordingVideo() : addVideo()"
                  style="text-transform: none"
                >
                  {{ $t("video.start_record") }}
                </v-btn>
              </v-col>
            </v-row>
            <div class="d-flex justify-center" v-if="recordingVideo">
              <VideoJSRecord ref="video" @getVideo="getVideo" />
            </div>
            <v-row class="pa-0 ma-0 my-2 mt-2" justify="center">
              <v-col cols="8" sm="8" md="5" class="pa-0">
                <v-file-input
                  small-chips
                  :class="candidate.video != null ? '' : 'd-none'"
                  id="video"
                  clear-icon="mdi-delete"
                  prepend-icon=""
                  @click:clear="resetVideo()"
                  :label="$tc('video.title', 1)"
                  truncate-length="15"
                  v-model="candidate.video"
                  capture="user"
                  accept="video/*"
                ></v-file-input>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <!-- <v-row  class="mt-6 align-center">
        <v-divider class="mx-10 mb-4 v-divider text--tertiary"></v-divider>
      </v-row> -->
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <v-checkbox v-model="candidate.is_only_state">
              <template v-slot:label>
                <div class="primary--text text-subtitle-1">
                  {{ $t("offer.search_only_province") }}
                </div>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
        <v-row
          class="pa-0 ma-0"
          justify-sm="center"
          v-if="!candidate.is_only_state"
        >
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <v-subheader class="pa-0 text-caption primary--text">{{
              $t("field.other_provinces")
            }}</v-subheader>
            <v-select
              :items="states"
              item-value="id"
              item-text="name"
              hide-details="auto"
              v-model="candidate.interested_states"
              multiple
              :label="$t('field.interest')"
              :disabled="candidate.is_only_state"
              :rules="!candidate.is_only_state ? required : []"
              outlined
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0 mt-6" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <v-checkbox
              class="ma-0"
              hide-details="auto"
              :rules="required"
              v-model="candidate.accept_terms_conditions"
              validate-on-blur
            >
              <template v-slot:label>
                <div class="primary--text text-subtitle-1">
                  {{ $tc("terms.accept", 1) }}
                  <a
                    class="blue--text text-subtitle-1 ma-0 pa-0"
                    @click="openLegalModal('TermsAndConditions')"
                    >{{ $t("terms.title") }}</a
                  >&nbsp;{{ $tc("terms.accept", 2) }}
                </div>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <v-checkbox
              class="ma-0"
              hide-details="auto"
              :rules="required"
              v-model="candidate.accept_privacy_policy"
              validate-on-blur
            >
              <template v-slot:label>
                <div class="primary--text text-subtitle-1">
                  {{ $t("privacy.accept") }}
                  <a
                    class="blue--text text-subtitle-1 ma-0 pa-0"
                    @click="openLegalModal('PrivacyPolicy')"
                    >{{ $t("privacy.title") }}</a
                  >
                  <!-- </span> -->
                </div>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
      </v-form>
    </div>
    <v-row class="mx-4 my-8 ma-0 pa-0" justify="end" justify-sm="center">
      <v-col cols="6" sm="4" md="2" class="pa-0 mt-2 mr-0 text-right">
        <div>
          <v-btn
            color="secondary"
            class="text-capitalize"
            block
            large
            rounded
            @click="createRegister"
            :disabled="!formValid"
          >
            {{ $t("operation.finish") }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import VideoJSRecord from "@/components/VideoJSRecord.vue";
import Legal from "@/components/modals/Legal.vue";
import { mapGetters } from "vuex";
export default {
  components: { Legal, VideoJSRecord },
  props: {
    address: {
      type: Object,
      required: true
    }
  },
  watch: {
    address: {
      handler() {
        let province = this.getCandidateStateId();
        if (province) this.candidate.interested_states.push(province.id);
      },
      deep: true
    }
  },
  data() {
    return {
      legalType: null,
      legalModal: false,
      formValid: true,
      recordingVideo: false,
      referrerSocial: [{id:'LKIN',name: 'LinkedIn'},{id:'INSG',name: 'Instragram'},{id:'GOOG',name: 'Google'},{id:'COLL',name: 'Colegio profesional'},{id:'OTHE',name: 'Otro'}],
      candidate: {
        referrer_name: '',
        curriculum: null,
        video: null,
        other_files: null,
        interested_states: [],
        is_only_state: false,
        accept_privacy_policy: false,
        accept_terms_conditions: false,
        avatar: null
      },
      interests: [],
      required: [v => !!v || this.$i18n.t("validation.required")]
    };
  },
  computed: {
    ...mapGetters({ titles: "core/titles" }),
    ...mapGetters({ states: "core/states" }),
    isMobileDevice() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    createRegister: function() {
      if (this.$refs.form.validate()) {
        if (this.candidate.video != null) {
          this.validateFile(this.candidate.video);
        } else {
          this.updateCandidateInfo();
          this.$emit("createRegister");
        }
      }
    },
    addAvatar() {
      document.getElementById("avatar").click();
    },
    addCv() {
      document.getElementById("cv").click();
    },
    addVideo() {
      document.getElementById("video").click();
    },
    addOtherFields() {
      document.getElementById("other").click();
    },
    getVideo(data) {
      document.getElementById("video").disabled = true;
      this.candidate.video = data;
    },
    openRecordingVideo() {
      this.recordingVideo = true;
    },
    resetVideo() {
      this.$refs.video.resetVideo();
    },
    updateCandidateInfo() {
      if (this.candidate.is_only_state)
        this.candidate.interested_states = this.getCandidateStateId()
          ? [this.getCandidateStateId().id]
          : [];
      this.$emit("updateCandidateInfo", this.candidate);
    },
    getCandidateStateId() {
      let province = this.address.locality.state;
      return this.states.find(elm => elm.code == province.code);
    },
    validateFile(file) {
      var instance = this;
      var video = document.createElement("video");
      video.preload = "metadata";
      video.onloadedmetadata = function() {
        window.URL.revokeObjectURL(video.src);
        if (video.duration < 50) {
          instance.notifyErrorDuration();
          return;
        }
        instance.updateCandidateInfo();
        instance.$emit("createRegister");
      };

      video.src = URL.createObjectURL(file);
    },
    notifyErrorDuration() {
      this.$notify({
        title: this.$i18n.t("notification.video_duration_error"),
        type: "error"
      });
    },
    openLegalModal(type) {
      if (type == "PrivacyPolicy") {
        this.candidate.accept_privacy_policy = !this.candidate
          .accept_privacy_policy;
      } else {
        this.candidate.accept_terms_conditions = !this.candidate
          .accept_terms_conditions;
      }
      this.legalType = type;
      this.legalModal = true;
    },
    closelegalModal() {
      this.legalModal = !this.legalModal;
    }
  }
};
</script>
<style scoped>
.v-divider {
  border-width: 0.5px;
  border-color: #718f94 !important;
}
a {
  text-decoration: underline;
}
</style>
