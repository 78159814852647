<template>
  <v-app-bar app flat color="#e5f1f2">
    <v-container>
      <v-row justify-sm="center">
        <v-col cols="12" md="6" sm="12" class="ma-0 pa-0">
          <v-toolbar-items>
            <v-icon style="cursor: pointer" @click="updateStep" class="ml-md-n3"
              >mdi-arrow-left</v-icon
            >
            <v-spacer></v-spacer>
            <v-menu
              offset-y
              :nudge-left="25"
              :nudge-bottom="5"
              class="rounded-lg"
            >
              <template v-slot:activator="{ on, attrs }">
                <div class="d-flex align-center" v-bind="attrs" v-on="on">
                  <v-img
                    alt="ApoTalent Logo"
                    class="shrink mr-2"
                    contain
                    src="@/assets/apotalent/Isotipo white.png"
                    transition="scale-transition"
                    width="35"
                  />

                  <v-img
                    alt="ApoTalent Name"
                    class="shrink mt-1"
                    contain
                    min-width="100"
                    src="@/assets/apotalent/ApoTalent.svg"
                    transition="scale-transition"
                    width="70"
                  />
                </div>
              </template>

              <v-list>
                <v-list-item
                  class="legal-options"
                  v-for="(option, index) in legalOptions"
                  :key="index"
                  :to="{ name: option.router }"
                  @click="() => {}"
                >
                  <v-icon small class="mr-2" color="primary">{{
                    option.icon
                  }}</v-icon>
                  <v-list-item-title class="secondary--text text-caption"
                    ><u>{{ $t(option.title) }}</u></v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>

            <v-spacer></v-spacer>

            <v-menu
              bottom
              left
              offset-y
              v-model="menu"
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" large color="primary"
                  >mdi-menu</v-icon
                >
              </template>
              <!-- options dropdown list -->
              <v-list class="rounded-lg">
                <v-list-item @click="menu = !menu" class="justify-end">
                  <v-icon color="primary">mdi-close</v-icon>
                </v-list-item>
                <v-row
                  dense
                  v-for="(option, index) in mainOptions"
                  :key="index"
                >
                  <v-list-item
                    :to="!!option.router ? { name: option.router } : ''"
                    target="_blank"
                    @click="option.key == 'language' ? toggleLanguage() : {}"
                  >
                    <i18n
                      @toggleLanguage="toggleLanguage"
                      :menu="langaugeMenu"
                      v-if="option.key == 'language'"
                    />
                    <v-list-item-title
                      class="font-weight-light secondary--text"
                    >
                      <v-icon color="primary">{{ option.icon }}</v-icon>
                      {{ $tc(option.title, 2) }}
                    </v-list-item-title>
                  </v-list-item>
                  <!-- <v-divider v-show="index == 2" class="mx-4"></v-divider> -->
                </v-row>
              </v-list>
            </v-menu>
          </v-toolbar-items>
        </v-col>
      </v-row>
    </v-container>
  </v-app-bar>
</template>
<script>
import i18n from "@/components/navbar/i18n.vue";
import legalOptions from "@/components/layouts/legalOptions.js";
import mainOptions from "@/components/layouts/mainOptions.js";
export default {
  components: {
    i18n,
  },
  data() {
    return {
      legalOptions: legalOptions,
      mainOptions: mainOptions,
      langaugeMenu: false,
      menu: false,
    };
  },
  props: {
    step: {
      required: true,
      type: Number,
    },
    steps: {
      required: true,
      type: Number,
    },
  },
  methods: {
    updateStep: function () {
      this.$emit("updateStep", this.step - 1);
    },
    toggleLanguage() {
      this.langaugeMenu = !this.langaugeMenu;
    },
  },
};
</script>
<style scoped>
.v-divider {
  border-width: 0.5px;
  border-color: #718f94 !important;
}
.legal-options {
  min-height: 25px;
}
.v-sheet.v-snack__wrapper {
  box-shadow: none !important;
}
</style>
