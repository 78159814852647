<template>
  <div>
    <legal
      @closelegalModal="closelegalModal"
      :showlegalModal.sync="legalModal"
      :type="legalType"
    />
    <div class="mx-4">
      <v-form ref="form" v-model="formValid" lazy-validation>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="6" md="4" class="pa-0">
            <h3 class="mt-4 font-weight-black">
              {{ $t("register.profile_data") }}
            </h3>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0 ma-0">
            <v-subheader class="pa-0 ma-0 text-caption primary--text">{{
              $t("field.web")
            }} <span class="black--text pa-1">{{ $t("validation.opt") }}</span
                ></v-subheader>
            <v-text-field
              :label="`${$t('field.web')} (http://)`"
              hide-details="auto"
              outlined
              v-model="pharmacy.web"
              validate-on-blur
              :rules="web_page"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <v-subheader class="pa-0 text-caption primary--text"
              >{{ $t("field.employee_number") }}<span class="red--text text-subtitle-2">*</span
              ></v-subheader
            >
            <v-select
              :items="pharmacyWorkerNumber"
              v-model="pharmacy.employees_number"
              hide-details="auto"
              validate-on-blur
              :label="$t('field.employee_number')"
              :rules="required_boolean"
              item-text="[1]"
              item-value="[0]"
              outlined
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0 ma-0 mt-2">
            <span class="text-subtitle-1">{{ $t("field.pharmacy_logo") }} </span> <span class="black--text pa-1">{{ $t("validation.opt") }}</span>
            <v-row class="pa-0 ma-0 my-2">
              <v-col cols="8" sm="8" md="4" class="pa-0">
                <v-btn
                  color="secondary"
                  class="mt-2"
                  block
                  outlined
                  large
                  rounded
                  @click="addlogo"
                  style="text-transform: none"
                >
                  {{ $t("register.pharmacy_logo") }} 
                  <v-icon right dark> mdi-paperclip </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row
              class="pa-0 ma-0 my-2 mt-2"
              justify="center"
              justify-sm="start"
            >
              <v-col cols="10" sm="8" md="4" class="pa-0">
                <v-file-input
                  small-chips
                  id="logo"
                  clear-icon="mdi-delete"
                  prepend-icon=""
                  :label="$t('field.logo')"
                  truncate-length="15"
                  v-model="pharmacy.logo"
                  :class="pharmacy.logo != null ? '' : 'd-none'"
                  accept="image/*"
                ></v-file-input>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0 mt-2 ml-2 my-2">
            <span>{{ $t("video.pharmacy_add") }}:</span><span class="black--text pa-1">{{ $t("validation.opt") }}</span>
            <!-- <h5 class="p-2 mt-2 secondary--text">- {{ $t("video.q1") }}</h5>
            <h5 class="p-2 mt-1 secondary--text">- {{ $t("video.q2") }}</h5> -->
        
            <v-row class="pa-0 ma-0 my-2">
              <v-col cols="10" sm="8" md="4" class="pa-0">
                <v-btn
                  color="secondary"
                  class="mt-2"
                  block
                  large
                  outlined
                  rounded
                  @click="addVideo"
                  style="text-transform: none"
                >
                  {{ $t("video.attach") }}
                  <v-icon right dark> mdi-paperclip </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row
              class="pa-0 ma-0 my-2 mt-2"
              justify="center"
              justify-sm="start"
            >
              <v-col cols="10" sm="8" md="4" class="pa-0">
                <v-file-input
                  small-chips
                  :class="pharmacy.video != null ? '' : 'd-none'"
                  id="video"
                  clear-icon="mdi-delete"
                  prepend-icon=""
                  :label="$t('video.attach')"
                  truncate-length="15"
                  v-model="pharmacy.video"
                  capture="user"
                  accept="video/*"
                ></v-file-input>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0 mb-2" justify-sm="center">
          <v-col xl="4" cols="10" sm="8" md="4" class="pa-0 ma-0 mt-2">
            <span class="text-subtitle-1">{{ $t("field.opening_hours") }} <span class="black--text pa-1">{{ $t("validation.opt") }}</span></span>
          </v-col>
        </v-row>
        <v-row
          class="pa-0 ma-0"
          justify-sm="center"
          v-for="(hours, index) in openHours"
          :key="index"
        >
          <v-col
            cols="6"
            sm="7"
            md="3"
            class="pa-0 text-h6"
            style="cursor: pointer"
            @click="addOpenHour(hours)"
          >
            <h5 class="mb-1 tertiary--text">- {{ hours.name }}</h5>
          </v-col>
          <v-col cols="1" class="pa-0 text-h6">
            <v-checkbox
              class="pa-0 ma-0 mt-1 float-right"
              hide-details="auto"
              color="success"
              v-model="pharmacy.open_hour"
              :value="hours.id"
              name="horario"
              :rules="required"
            >
            </v-checkbox>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0 mt-6" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <v-checkbox
              class="ma-0"
              hide-details="auto"
              :rules="required"
              v-model="pharmacy.accept_terms_conditions"
              validate-on-blur
            >
              <template v-slot:label>
                <div class="primary--text text-subtitle-1">
                  {{ $tc("terms.accept", 1) }}
                  <a
                    class="blue--text text-subtitle-1 ma-0 pa-0"
                    @click="openLegalModal('TermsAndConditions')"
                    >{{ $t("terms.title") }}</a
                  >&nbsp;{{ $tc("terms.accept", 2) }}
                </div>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <v-checkbox
              class="ma-0"
              hide-details="auto"
              :rules="required"
              v-model="pharmacy.accept_privacy_policy"
              validate-on-blur
            >
              <template v-slot:label>
                <div class="primary--text text-subtitle-1">
                  {{ $t("privacy.accept") }}
                  <!-- <span @click="openLegalModal('PrivacyPolicy')"> -->
                  <a
                    class="blue--text text-subtitle-1 ma-0 pa-0"
                    @click="openLegalModal('PrivacyPolicy')"
                    >{{ $t("privacy.title") }}</a
                  >
                  <!-- </span> -->
                </div>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
      </v-form>
    </div>
    <v-row class="mx-4 mb-2 mt-10" justify="end" justify-sm="center">
      <v-col cols="6" md="2" class="pa-0 mt-2 mr-0 text-right">
        <div>
          <v-btn
            color="secondary"
            block
            large
            rounded
            @click="createRegister"
            :disabled="!formValid"
          >
            {{ $t("operation.next") }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import Legal from "@/components/modals/Legal.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: { Legal },
  data() {
    return {
      legalType: null,
      legalModal: false,
      formValid: true,
      pharmacy: {
        accept_terms_conditions: false,
        accept_privacy_policy: false,
        open_hour: null,
        logo: null,
        web: null,
        employees_number: null
      },
      required: [v => !!v || this.$i18n.t("validation.required")],
      required_boolean: [v => v != null || this.$i18n.t("validation.required")],
      web_page: [
        v =>
          !v ||
          /^(?:http(s)?:\/\/)[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/.test(
            v
          ) ||
          this.$i18n.t("validation.web_page")
      ]
    };
  },
  created() {
    this.getOpenHours();
    this.getPharmacyWorkerNumber();
  },
  computed: {
    ...mapGetters({ openHours: "core/openHours" }),
    ...mapGetters({ pharmacyWorkerNumber: "core/pharmacyWorkerNumber" })
  },
  methods: {
    ...mapActions({ getOpenHours: "core/getOpenHours" }),
    ...mapActions({ getPharmacyWorkerNumber: "core/getPharmacyWorkerNumber" }),
    createRegister: function() {
      if (this.$refs.form.validate()) {
        this.updatePharmacyInfo();
        this.$emit("createRegister");
      }
    },
    addlogo() {
      document.getElementById("logo").click();
    },
    addVideo() {
      document.getElementById("video").click();
    },
    addOpenHour(value) {
      this.pharmacy.open_hour = value.id;
    },
    updatePharmacyInfo: function() {
      this.$emit("updatePharmacyInfo", this.pharmacy);
    },
    openLegalModal(type) {
      if (type == "PrivacyPolicy") {
        this.pharmacy.accept_privacy_policy = !this.pharmacy
          .accept_privacy_policy;
      } else {
        this.pharmacy.accept_terms_conditions = !this.pharmacy
          .accept_terms_conditions;
      }
      this.legalType = type;
      this.legalModal = true;
    },
    closelegalModal() {
      this.legalModal = !this.legalModal;
    }
  }
};
</script>
<style scoped></style>
