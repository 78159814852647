<template>
  <div>
    <div class="mx-4">
      <v-form ref="form" v-model="formValid" lazy-validation>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <h3 class="mt-4 mb-2 font-weight-bold">
              {{ $t("register.curricular_data") }}
            </h3>
          </v-col>
        </v-row>
        <!-- Candidate titles -->
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0 ma-0">
            <v-subheader class="pa-0 ma-0 text-caption primary--text"
              >{{ $t("field.title")
              }}<span class="red--text text-subtitle-2">*</span></v-subheader
            ><v-select
              :items="titles"
              v-model="candidate.title"
              hide-details="auto"
              :label="$t('field.title')"
              item-text="name"
              :rules="required"
              item-value="id"
              outlined
            ></v-select>
          </v-col>

          <!-- <v-col cols="6" sm="2" md="1" class="pa-0 ma-0">
            <v-subheader class="pa-0 ma-0 text-caption primary--text"
              ><span class="red--text text-subtitle-2">*</span
              >{{ $t("field.work_years") }}</v-subheader
            >
            <v-text-field
              v-model="candidate.experience_time"
              name="input-10-1"
              :label="$t('field.years')"
              hide-details="auto"
              :rules="required"
              inputmode="numeric"
              outlined
            >
            </v-text-field>
          </v-col> -->
        </v-row>
        <!-- Candidate Data when is a pharmacist -->

        <div v-if="candidate.title == pharmacistTitle">
          <v-row class="pa-0 ma-0" justify-sm="center">
            <v-col cols="12" sm="8" md="4" class="pa-0 ma-0">
              <v-subheader class="pa-0 text-caption primary--text"
                ><span class="red--text text-subtitle-2">*</span
                >{{ $t("field.has_license") }}</v-subheader
              >
            </v-col>
          </v-row>
          <v-row class="pa-0 ma-0" justify-sm="center">
            <v-col cols="12" sm="8" md="4" class="pa-0 ma-0">
              <v-radio-group
                v-model="candidate.profile.has_license"
                row
                :rules="required_boolean"
                class="ma-0"
              >
                <v-radio :value="true">
                  <template v-slot:label>
                    <div
                      class="primary--text text-subtitle-1 font-weight-normal"
                    >
                      {{ $t("field.yes") }}
                    </div>
                  </template></v-radio
                >
                <v-radio :value="false">
                  <template v-slot:label>
                    <div
                      class="primary--text text-subtitle-1 font-weight-normal"
                    >
                      {{ $t("field.no") }}
                    </div>
                  </template></v-radio
                >
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row
            v-if="candidate.profile.has_license"
            class="pa-0 ma-0"
            justify-sm="center"
          >
            <v-col cols="12" sm="8" md="4" class="pa-0 ma-0">
              <v-subheader class="pa-0 ma-0 text-caption primary--text">{{
                $t("field.license")
              }}</v-subheader
              ><v-text-field
                v-model="candidate.profile.license"
                :label="$t('field.license')"
                maxlength="9"
                validate-on-blur
                hide-details="auto"
                outlined
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row
            v-if="
              candidate.profile.has_license &&
              candidate.profile.university != otherUniversity
            "
            class="pa-0 ma-0"
            justify-sm="center"
          >
            <v-col cols="12" sm="8" md="4" class="pa-0 ma-0">
              <v-subheader class="pa-0 ma-0 text-caption primary--text"
                ><span class="red--text text-subtitle-2">*</span
                >{{ $t("field.license_state") }}</v-subheader
              ><v-autocomplete
                :items="states"
                item-value="id"
                item-text="name"
                validate-on-blur
                :rules="candidate.profile.has_license ? required : []"
                v-model="candidate.profile.license_state"
                hide-details="auto"
                :label="$t('field.license_state')"
                outlined
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="pa-0 ma-0" justify-sm="center">
            <v-col cols="12" sm="8" md="4" class="pa-0 ma-0">
              <v-subheader class="pa-0 ma-0 text-caption primary--text"
                ><span class="red--text text-subtitle-2">*</span
                >{{ $t("field.university") }}</v-subheader
              ><v-select
                :items="universities"
                item-value="id"
                item-text="name"
                validate-on-blur
                :rules="required_boolean"
                v-model="candidate.profile.university"
                hide-details="auto"
                :label="$t('field.university')"
                outlined
              ></v-select>
            </v-col>
          </v-row>
          <v-row
            v-if="candidate.profile.university == otherUniversity"
            class="pa-0 ma-0"
            justify-sm="center"
          >
            <v-col cols="12" sm="8" md="4" class="pa-0 ma-0">
              <v-subheader class="pa-0 ma-0 text-caption primary--text"
                ><span class="red--text text-subtitle-2">*</span
                >{{ $t("field.homologated_status") }}</v-subheader
              ><v-select
                :items="homologatedChoices"
                item-value="[0]"
                item-text="[1]"
                validate-on-blur
                :rules="candidate.profile.has_license ? required : []"
                v-model="candidate.profile.homologated_status"
                hide-details="auto"
                :label="$t('field.homologated_status')"
                outlined
              ></v-select>
            </v-col>
          </v-row>
          <v-row class="pa-0 ma-0" justify-sm="center">
            <v-col cols="12" sm="8" md="4" class="pa-0 ma-0">
              <v-subheader class="pa-0 ma-0 text-caption primary--text"
                ><span class="red--text text-subtitle-2">*</span
                >{{ $t("field.experience") }}</v-subheader
              ><v-select
                :items="pharmacyExperienceYears"
                item-value="[0]"
                item-text="[1]"
                validate-on-blur
                :rules="required_boolean"
                v-model="candidate.profile.experience"
                hide-details="auto"
                :label="$t('field.experience')"
                outlined
              ></v-select>
            </v-col>
          </v-row>
        </div>
        <!-- Candidate educations -->
        <div
          v-for="(e, index) in candidate.educations"
          :key="index + 'Educations'"
        >
          <v-row class="pa-0 ma-0" justify-sm="center">
            <v-col cols="12" sm="8" md="4" class="pa-0 ma-0">
              <v-subheader class="pa-0 ma-0 text-caption primary--text"
                >{{ $t("field.other_title") }}
                <div class="black--text font-weight-light pa-1">
                  {{ $t("validation.opt") }}
                </div> </v-subheader
              ><v-text-field
                v-model="e.title"
                name="input-10-1"
                :label="$t('field.other_title')"
                hide-details="auto"
                outlined
              >
              </v-text-field>
            </v-col>
          </v-row>
        </div>
        <v-row class="pa-0 ma-0 mt-2" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0 ma-0">
            <a @click="appendStudies" class="text-subtitle-1"
              >+ {{ $t("title.add") }}</a
            >
          </v-col>
        </v-row>
        <!-- Candidate specializations -->
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0 ma-0">
            <v-subheader class="pa-0 ma-0 text-caption primary--text"
              >{{ $t("field.specialization") }}
              <div class="black--text font-weight-light pa-1">
                {{ $t("validation.opt") }}
              </div> </v-subheader
            ><v-select
              :items="expertises"
              item-value="id"
              multiple
              item-text="name"
              v-model="candidate.expertises"
              hide-details="auto"
              :label="$t('field.specialization')"
              outlined
            ></v-select>
          </v-col>
        </v-row>
        <!-- Candidate experiencie area -->
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0 ma-0">
            <v-subheader class="pa-0 ma-0 text-caption primary--text"
              >{{ $t("field.experience_fields") }}
              <div class="black--text font-weight-light pa-1">
                {{ $t("validation.opt") }}
              </div> </v-subheader
            ><v-select
              :items="experienceFields"
              item-value="id"
              multiple
              item-text="name"
              v-model="candidate.experience_fields"
              hide-details="auto"
              :label="$t('field.experience_fields')"
              outlined
            ></v-select>
          </v-col>
        </v-row>
        <!-- Candidate experiencie area -->
        <!-- Working Days -->
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <v-subheader class="pa-0 text-caption primary--text"
              >{{ $t("offer.working_type")
              }}<span class="red--text text-subtitle-2">*</span></v-subheader
            >
            <v-select
              v-model="candidate.working_day"
              :items="workingDays"
              item-value="id"
              multiple
              item-text="name"
              hide-details="auto"
              :rules="required_boolean"
              :label="$t('offer.working_type')"
              outlined
            ></v-select>
          </v-col>
        </v-row>
        <!-- Working Days -->
        <!-- Computer programs -->
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <v-subheader class="pa-0 text-caption primary--text">{{
              $t("register.computer_programs")
            }} <div class="black--text font-weight-light pa-1">
                {{ $t("validation.opt") }}
              </div></v-subheader>
            <v-select
              v-model="candidate.computer_programs"
              :items="computerPrograms"
              item-value="id"
              multiple
              item-text="name"
              hide-details="auto"
              :label="$t('register.type_of_programs')"
              outlined
            ></v-select>
          </v-col>
        </v-row>

        <v-row
          v-for="(e, index) in candidate.othersComputerPrograms"
          :key="index"
          class="pa-0 ma-0"
          justify-sm="center"
        >
          <v-col cols="12" sm="8" md="4" class="pa-0 ma-0">
            <v-subheader class="pa-0 ma-0 text-caption primary--text">
              {{ $t("register.others_computer_programs") }}
              <div class="black--text font-weight-light pa-1">
                {{ $t("validation.opt") }}
              </div> </v-subheader
            ><v-text-field
              v-model="e.programs"
              name="input-10-1"
              :label="$t('register.others')"
              hide-details="auto"
              outlined
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0 mt-2" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0 ma-0">
            <a @click="appendComputerPrograms" class="text-subtitle-1"
              >+ {{ $t("register.add_another") }}</a
            >
          </v-col>
        </v-row>
        <!-- Computer programs -->
        <!-- Candidate languages -->
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0 ma-0">
            <v-subheader class="pa-0 ma-0 text-caption primary--text"
              >{{ $t("field.languages_candidate") }}
              <div class="black--text font-weight-light pa-1">
                {{ $t("validation.opt") }}
              </div> </v-subheader
            ><v-select
              :items="languages"
              item-value="id"
              multiple
              item-text="name"
              v-model="candidate.candidate_languages"
              hide-details="auto"
              :label="$t('field.languages_candidate')"
              outlined
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0 ma-0">
            <v-subheader class="pa-0 text-caption primary--text">{{
              $t("field.pharmacy_experience")
            }}</v-subheader>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0 ma-0">
            <v-radio-group
              v-model="candidate.pharmacy_experience"
              row
              class="ma-0"
            >
              <v-radio :value="true">
                <template v-slot:label>
                  <div class="primary--text text-subtitle-1 font-weight-normal">
                    {{ $t("field.yes") }}
                  </div>
                </template></v-radio
              >
              <v-radio :value="false">
                <template v-slot:label>
                  <div class="primary--text text-subtitle-1 font-weight-normal">
                    {{ $t("field.no") }}
                  </div>
                </template></v-radio
              >
            </v-radio-group>
          </v-col>
        </v-row>
      </v-form>
    </div>
    <v-row class="mx-4 my-8" justify="end" justify-sm="center">
      <v-col cols="6" sm="4" md="2" class="pa-0 mt-2 mr-0 text-right">
        <div>
          <v-btn
            color="secondary"
            block
            large
            rounded
            @click="updateStep"
            :disabled="!formValid"
          >
            {{ $t("operation.next") }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import config from "@/config";

export default {
  data() {
    return {
      formValid: true,
      candidate: {
        experience_fields: [],
        candidate_languages: [],
        educations: [{ title: "" }],
        experience_time: null,
        title: null,
        expertises: [],
        pharmacy_experience: null,
        working_day: [],
        othersComputerPrograms: [],
        computer_programs: [],
        profile: {},
      },
      required: [(v) => !!v || this.$i18n.t("validation.required")],
      required_boolean: [
        (v) => v != null || this.$i18n.t("validation.required"),
      ],
    };
  },
  async created() {
    this.getPharmacyExperienceYears();
    this.getWorkingDays();
    this.getComputerPrograms();
    this.getPositions();
    this.getTitles();
    this.getStates();
    this.getExpertises();
    this.getExperienceFields();
    this.getHomologatedChoices();
    this.getUniversities();
    await this.getLanguages();
    this.defaultLanguage();
  },
  computed: {
    ...mapGetters({ positions: "core/positions" }),
    ...mapGetters({ titles: "core/titles" }),
    ...mapGetters({ expertises: "core/expertises" }),
    ...mapGetters({ experienceFields: "core/experienceFields" }),
    ...mapGetters({ languages: "core/languages" }),
    ...mapGetters({ workingDays: "core/workingDays" }),
    ...mapGetters({ computerPrograms: "core/computerPrograms" }),
    ...mapGetters({
      pharmacyExperienceYears: "core/pharmacyExperienceYears",
    }),
    ...mapGetters({ states: "core/states" }),
    ...mapGetters({ universities: "core/universities" }),
    ...mapGetters({ homologatedChoices: "core/homologatedChoices" }),
    pharmacistTitle() {
      return config.CONSTANTS.PHARMACIST_TITLE;
    },
    otherUniversity() {
      return config.CONSTANTS.OTHER_UNIVERSITY;
    },
  },
  methods: {
    ...mapActions({ getPositions: "core/getPositions" }),
    ...mapActions({ getTitles: "core/getTitles" }),
    ...mapActions({ getExpertises: "core/getExpertises" }),
    ...mapActions({ getExperienceFields: "core/getExperienceFields" }),
    ...mapActions({ getLanguages: "core/getLanguages" }),
    ...mapActions({ getWorkingDays: "core/getWorkingDays" }),
    ...mapActions({ getComputerPrograms: "core/getComputerPrograms" }),
    ...mapActions({
      getPharmacyExperienceYears: "core/getPharmacyExperienceYears",
    }),
    ...mapActions({ getStates: "core/getStates" }),
    ...mapActions({ getUniversities: "core/getUniversities" }),
    ...mapActions({ getHomologatedChoices: "core/getHomologatedChoices" }),

    redirectLogin() {
      this.$router.push({ name: "login" });
    },
    appendStudies() {
      this.candidate.educations.push({
        title: "",
      });
    },
    appendComputerPrograms() {
      this.candidate.othersComputerPrograms.push({
        programs: "",
      });
    },
    defaultLanguage() {
      const language = this.languages.filter((l) => {
        return l.name_es == "Español";
      });
      this.candidate.candidate_languages.push(language[0].id);
    },
    updateStep: function () {
      if (this.$refs.form.validate()) {
        this.updateCandidateInfo();
        this.$emit("updateStep", 3);
      }
    },
    updateCandidateInfo() {
      if (this.candidate.title != this.pharmacistTitle) {
        this.candidate.profile = {};
      }
      if (this.candidate.university == this.otherUniversity)
        this.candidate.license_state = null;
      this.$emit("updateCandidateInfo", this.candidate);
    },
  },
};
</script>
<style scoped></style>
