<template>
  <div>
    <div class="mx-4">
      <v-form ref="form" v-model="formValid" lazy-validation>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <h3 class="mt-4 font-weight-black">
              {{ $t("register.pharmacist_data") }}
            </h3>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0 ma-0">
            <v-subheader class="pa-0 ma-0 text-caption primary--text"
              >{{ $t("field.pharmacist_name")
              }}<span class="red--text text-subtitle-2">*</span></v-subheader
            >
            <v-text-field
              :label="$t('field.full_name')"
              v-model="pharmacyOwner.full_name"
              hide-details="auto"
              outlined
              :rules="required"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <!-- <v-col cols="4" sm="2" md="1" class="pa-0">
            <v-subheader class="pa-0 text-caption primary--text"
              ><span class="red--text text-subtitle-2">*</span>
              {{ $t("field.country_code") }}
            </v-subheader>
            <v-select
              :items="code"
              v-model="pharmacy_code"
              hide-details="hide"
              :label="$t('field.country_code')"
              :rules="required"
              item-text="code"
              item-value="code"
              outlined
            ></v-select>
          </v-col> -->
          <!-- <v-col cols="8" sm="6" md="3" class="pa-0">
            <v-subheader class="pa-0 text-caption primary--text"
              ><span class="red--text text-subtitle-2">*</span>
              {{ $t("field.contact_phone_number") }}
            </v-subheader>
            <v-text-field
              name="input-10-1"
              :label="$t('field.phone_number')"
              :rules="mobile_number"
              validate-on-blur
              hide-details="auto"
              outlined
              v-mask="'###############'"
              v-model="pharmacy_phone_number"
              @change="pharmacy_phone()"
            >
            </v-text-field>
          </v-col> -->
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <v-subheader class="pa-0 text-caption primary--text">
              {{ $t("field.contact_phone_number")
              }}<span class="red--text text-subtitle-2">*</span>
            </v-subheader>
            <vue-tel-input
              :requiredPass="requiredDefault"
              :phoneNumber="pharmacyOwner.phone_number"
              @updatePhoneNumber="updatePhoneNumber"
              @updateFormValid="updateFormValid"
              @updateCountry="updateCountry1"
            />
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <v-checkbox
              class="mt-6"
              v-model="contact"
              @click="changeValidation"
            >
              <template v-slot:label>
                <div class="primary--text text-subtitle-1">
                  {{ $t("contact.add") }}
                </div>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
        <div v-if="contact">
          <v-row class="pa-0 ma-0" justify-sm="center">
            <v-col cols="12" sm="8" md="4" class="pa-0 ma-0">
              <v-subheader class="pa-0 ma-0 text-caption primary--text"
                >{{ $t("field.contact_name")
                }}<span class="red--text text-subtitle-2">*</span></v-subheader
              >
              <v-text-field
                :label="$t('field.contact_name')"
                v-model="pharmacyContact.full_name"
                hide-details="auto"
                outlined
                :rules="contact ? required : []"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="pa-0 ma-0" justify-sm="center">
            <!-- <v-col cols="4" sm="2" md="1" class="pa-0">
              <v-subheader class="pa-0 text-caption primary--text"
                ><span class="red--text text-subtitle-2">*</span>
                {{ $t("field.country_code") }}
              </v-subheader>
              <v-select
                :items="code"
                v-model="contact_code"
                hide-details="hide"
                :label="$t('field.country_code')"
                :rules="required"
                item-text="code"
                item-value="code"
                outlined
              ></v-select>
            </v-col>
            <v-col cols="12" sm="8" md="4" class="pa-0">
              <v-subheader class="pa-0 text-caption primary--text"
                ><span class="red--text text-subtitle-2">*</span>
                {{ $t("field.contact_phone_number") }}
              </v-subheader>
              <v-text-field
                name="input-10-1"
                :label="$t('field.contact_phone_number')"
                :rules="mobile_number"
                validate-on-blur
                hide-details="auto"
                outlined
                v-mask="'###############'"
                v-model="contact_phone_number"
                @change="contact_phone()"
              >
              </v-text-field>
            </v-col> -->
            <v-col cols="12" sm="8" md="4" class="pa-0">
              <v-subheader class="pa-0 text-caption primary--text">
                {{ $t("field.contact_phone_number") }}
                <span class="red--text text-subtitle-2">*</span>
              </v-subheader>
              <vue-tel-input
                :requiredPass="contact"
                :phoneNumber="pharmacyContact.phone_number"
                @updatePhoneNumber="updatePhoneNumber2"
                @updateFormValid="updateFormValid2"
                @updateCountry="updateCountry2"
              />
            </v-col>
          </v-row>
          <v-row class="pa-0 ma-0" justify-sm="center">
            <v-col cols="12" sm="8" md="4" class="pa-0 ma-0">
              <v-subheader class="pa-0 ma-0 text-caption primary--text"
                >{{ $t("field.contact_email")
                }}<span class="red--text text-subtitle-2">*</span></v-subheader
              >
              <v-text-field
                v-model="pharmacyContact.email"
                :label="$t('field.contact_email')"
                hide-details="auto"
                outlined
                :rules="contact ? required : []"
              ></v-text-field>
            </v-col>
          </v-row>
        </div>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <h3 class="mt-4 font-weight-black">
              {{ $t("register.pharmacy_data") }}
            </h3>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <v-subheader class="pa-0 text-caption primary--text">
              {{ $t("field.commercial_name")
              }}<span class="black--text pa-1">{{
                $t("validation.opt")
              }}</span></v-subheader
            >
            <v-text-field
              v-model="pharmacy.commercial_name"
              name="input-10-1"
              :label="$t('field.commercial_name')"
              hide-details="auto"
              outlined
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0 ma-0">
            <v-subheader class="pa-0 ma-0 text-caption primary--text"
              >{{ $t("field.cif")
              }}<span class="red--text text-subtitle-2">*</span></v-subheader
            >
            <v-text-field
              :label="$t('field.cif_format')"
              v-model="pharmacy.cif"
              :rules="cif"
              validate-on-blur
              hide-details="auto"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0 ma-0">
            <v-subheader class="pa-0 ma-0 text-caption primary--text"
              >{{ $t("field.pharmacy_number")
              }}<span class="black--text pa-1">{{
                $t("validation.opt")
              }}</span></v-subheader
            >
            <v-text-field
              v-model="pharmacy.number"
              :label="$t('field.pharmacy_number')"
              color="primary"
              hide-details="auto"
              v-mask="'#########'"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0 ma-0">
            <v-subheader class="pa-0 ma-0 text-caption primary--text">
              <!-- <span class="red--text text-subtitle-2">*</span> -->
              {{ $t("field.soe") }}
              <span class="black--text pa-1">{{ $t("validation.opt") }}</span>
            </v-subheader>
            <v-text-field
              v-model="pharmacy.soe"
              :label="$t('field.soe')"
              color="primary"
              hide-details="auto"
              v-mask="'#########'"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- Computer programs -->
        <v-row class="pa-0 ma-0" justify-sm="center" v-if="!others_programs">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <v-subheader class="pa-0 text-caption primary--text"
              >{{ $t("register.computer_programs") }}
              <span class="red--text text-subtitle-2">*</span>
            </v-subheader>
            <v-select
              v-model="pharmacy.computer_programs"
              :items="computerPrograms"
              item-value="id"
              item-text="name"
              hide-details="auto"
              :rules="required"
              :label="$t('register.type_of_programs')"
              outlined
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <v-checkbox
              class="mt-6"
              v-model="others_programs"
              @click="others_programs == !others_programs"
            >
              <template v-slot:label>
                <div class="primary--text text-subtitle-1">
                  {{ $t("register.others_computer_programs") }}
                </div>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center" v-if="others_programs">
          <v-col cols="12" sm="8" md="4" class="pa-0 ma-0">
            <v-subheader class="pa-0 ma-0 text-caption primary--text">
              {{ $t("register.add_another") }}
              <span class="red--text text-subtitle-2">*</span> </v-subheader
            ><v-text-field
              v-model="pharmacy.othersComputerPrograms"
              name="input-10-1"
              :label="$t('register.others')"
              hide-details="auto"
              :rules="required"
              outlined
            >
            </v-text-field>
          </v-col>
        </v-row>

        <!-- Computer programs -->
        <v-row class="pa-0 ma-0" justify-sm="center">
          <!-- <v-col cols="4" sm="2" md="1" class="pa-0">
            <v-subheader class="pa-0 text-caption primary--text"
              ><span class="red--text text-subtitle-2">*</span>
              {{ $t("field.country_code") }}
            </v-subheader>
            <v-select
              :items="code"
              v-model="country_code"
              hide-details="hide"
              :label="$t('field.country_code')"
              :rules="required"
              item-text="code"
              item-value="code"
              outlined
            ></v-select>
          </v-col>
          <v-col cols="8" sm="6" md="3" class="pa-0">
            <v-subheader class="pa-0 text-caption primary--text"
              ><span class="red--text text-subtitle-2">*</span>
              {{ $t("field.phone_number") }}
            </v-subheader>
            <v-text-field
              name="input-10-1"
              :label="$t('field.phone_number')"
              :rules="mobile_number"
              validate-on-blur
              hide-details="auto"
              outlined
              v-model="phone_number"
              v-mask="'###############'"
              @change="phone()"
            >
            </v-text-field>
            
          </v-col> -->
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <v-subheader class="pa-0 text-caption primary--text">
              {{ $t("field.contact_phone_number") }}
              <span class="red--text text-subtitle-2">*</span>
            </v-subheader>
            <vue-tel-input
              :requiredPass="requiredDefault"
              :phoneNumber="pharmacy.phone_number"
              @updatePhoneNumber="updatePhoneNumber3"
              @updateFormValid="updateFormValid3"
              @updateCountry="updateCountry3"
            />
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <v-subheader class="pa-0 text-caption primary--text"
              >{{ $t("field.address") }}
              <span class="red--text text-subtitle-2">*</span></v-subheader
            >
            <vuetify-google-autocomplete
              ref="address"
              id="map2"
              classname="form-control"
              @change="validateAddress('address', $event)"
              :label="$t('field.address_placeholder')"
              outlined
              validate-on-blur
              :rules="address_validator1"
              :hide-details="false"
              country="es"
              v-on:placechanged="getAddressData"
            >
            </vuetify-google-autocomplete>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <v-checkbox class="mt-6" v-model="pharmacy.billing_match_data">
              <template v-slot:label>
                <div class="primary--text text-subtitle-1">
                  {{ $t("field.pharmacy_match_billing") }}
                </div>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
        <div v-if="!pharmacy.billing_match_data">
          <v-row class="pa-0 ma-0" justify-sm="center">
            <v-col cols="12" sm="8" md="4" class="pa-0">
              <v-subheader class="pa-0 text-caption primary--text"
                >{{ $t("field.fiscal_name")
                }}<span class="black--text pa-1">{{
                  $t("validation.opt")
                }}</span></v-subheader
              >
              <v-text-field
                name="input-10-1"
                v-model="pharmacy.fiscal_name"
                :label="$t('field.fiscal_name')"
                hide-details="auto"
                :rules="required"
                outlined
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="pa-0 ma-0" justify-sm="center">
            <v-col cols="12" sm="8" md="4" class="pa-0">
              <v-subheader class="pa-0 text-caption primary--text"
                >{{ $t("field.fiscal_address")
                }}<span class="red--text text-subtitle-2">*</span></v-subheader
              >
              <vuetify-google-autocomplete
                ref="address"
                :id="'map3'"
                placeholder=""
                @change="validateAddress('billing_address', $event)"
                classname="form-control"
                :rules="address_validator2"
                validate-on-blur
                :label="$t('field.address_placeholder')"
                outlined
                :hide-details="false"
                v-on:placechanged="getBillingAddressData"
                country="es"
              >
              </vuetify-google-autocomplete>
            </v-col>
          </v-row>
        </div>
      </v-form>
    </div>

    <v-row class="mx-4 my-4" justify="end" justify-sm="center">
      <v-col cols="6" sm="4" md="2" class="pa-0 mt-2 mr-0 text-right">
        <div>
          <v-btn
            color="secondary"
            block
            large
            rounded
            @click="updateStep"
            :disabled="
              !formValid || !phoneValid || !phoneValid2 || !phoneValid3
            "
          >
            {{ $t("operation.next") }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

import VuetifyGoogleAutocomplete from "vuetify-google-autocomplete";
import VueTelInput from "@/components/shared/VueTelInput.vue";

export default {
  components: {
    VuetifyGoogleAutocomplete,
    VueTelInput,
  },
  data() {
    return {
      requiredDefault: true,
      requiredDefault2: true,
      phoneValid: false,
      phoneValid2: true,
      phoneValid3: false,
      others_programs: false,
      country1: null,
      country2: null,
      country3: null,
      formValid: false,
      show1: false,
      type: null,
      country_code: null,
      phone_number: null,
      contact: false,
      pharmacy_phone_number: null,
      pharmacy_code: null,
      contact_phone_number: null,
      contact_code: null,
      pharmacyOwner: {
        full_name: null,
        phone_number: null,
      },
      pharmacyContact: {
        full_name: null,
        phone_number: null,
        email: null,
      },
      pharmacy: {
        number: null,
        phone_number: null,
        billing_match_data: false,
        fiscal_name: null,
        commercial_name: null,
        cif: null,
        soe: null,
        othersComputerPrograms: null,
        computer_programs: null,
        billing_address: {
          raw: null,
          route: null,
          street_number: null,
          formatted: null,
          latitude: null,
          longitude: null,
          locality: {
            postal_code: null,
            name: null,
            state: {
              name: null,
              code: null,
              country: {
                name: null,
                code: null,
              },
            },
          },
        },
        address: {
          raw: null,
          route: null,
          street_number: null,
          formatted: null,
          latitude: null,
          longitude: null,
          locality: {
            postal_code: null,
            name: null,
            state: {
              name: null,
              code: null,
              country: {
                name: null,
                code: null,
              },
            },
          },
        },
      },
      required: [(v) => !!v || this.$i18n.t("validation.required")],
      mobile_number: [
        (v) => !!v || this.$i18n.t("validation.required"),
        (v) =>
          /^\+?[0-9]{1,3}[\s-]?[0-9]{8,12}$/.test(v) ||
          this.$i18n.t("validation.phone_number"),
      ],
      cif: [
        (v) => !!v || this.$i18n.t("validation.required"),
        (v) =>
          /(^[a-zA-Z]{1}\d{7}[a-zA-Z0-9]{1}$)|(^\d{8}[a-zA-Z]{1}$)/.test(v) ||
          this.$i18n.t("validation.cif"),
      ],

      address_validator1: [
        (v) => !!v || this.$i18n.t("validation.required"),
        (v) =>
          (v &&
            this.pharmacy.address.latitude &&
            this.pharmacy.address.latitude != 0) ||
          this.$i18n.t("validation.address"),
      ],
      address_validator2: [
        (v) => !!v || this.$i18n.t("validation.required"),
        (v) =>
          (v &&
            this.pharmacy.billing_address.latitude &&
            this.pharmacy.billing_address.latitude != 0) ||
          this.$i18n.t("validation.address"),
      ],
      // Doesnt work as intended
      // address_validator: [
      //   (v) => !!v || this.$i18n.t("validation.required"),
      //   (v) => {
      //     let index = [
      //       this.pharmacy.address.formatted,
      //       this.pharmacy.billing_address.formatted,
      //     ].indexOf(v);
      //     let loc =
      //       index == 0
      //         ? this.pharmacy.address.longitude
      //         : this.pharmacy.billing_address.longitude;
      //     return (v && loc && loc != 0) || this.$i18n.t("validation.address");
      //   },
      // ],
      code: [
        {
          name: "España (+34)",
          icon: "",
          code: "+34 ",
        },
      ],
    };
  },
  async created() {
    this.getComputerPrograms();
  },
  computed: {
    ...mapGetters({ computerPrograms: "core/computerPrograms" }),
  },
  methods: {
    ...mapActions({ getComputerPrograms: "core/getComputerPrograms" }),

    validateAddress(address, formatted) {
      if (
        this.pharmacy[address].formatted != formatted &&
        this.pharmacy[address].latitude
      ) {
        this.pharmacy[address] = {};
      }
    },
    changeValidation() {
      this.phoneValid2 = !this.phoneValid2;
      if (this.contact == false) {
        this.pharmacyContact.full_name = null;
        this.pharmacyContact.phone_number = null;
        this.pharmacyContact.email = null;
      }
    },
    updateFormValid(valid) {
      this.phoneValid = valid;
    },
    updateFormValid2(valid) {
      this.phoneValid2 = valid;
    },
    updateFormValid3(valid) {
      this.phoneValid3 = valid;
    },
    updateCountry1(country) {
      this.country1 = "+" + country;
    },
    updateCountry2(country) {
      this.country2 = "+" + country;
    },
    updateCountry3(country) {
      this.country3 = "+" + country;
    },
    updatePhoneNumber(phone) {
      this.pharmacyOwner.phone_number = phone;
    },
    updatePhoneNumber2(phone) {
      this.pharmacyContact.phone_number = phone;
    },
    updatePhoneNumber3(phone) {
      this.pharmacy.phone_number = phone;
    },
    updateStep: function () {
      var ban = 0;
      if (this.pharmacyOwner.phone_number == null) {
        ban = 1;
        this.pharmacyOwner.phone_number = " ";
      }
      if (this.pharmacy.phone_number == null) {
        ban = 1;
        this.pharmacy.phone_number = " ";
      }
      if (this.pharmacyContact.phone_number == null && this.contact == true) {
        ban = 1;
        this.pharmacyContact.phone_number = " ";
      }

      if (ban == 0) {
        if (this.$refs.form.validate()) {
          this.formatOwnerTelephone();
          this.formatContactTelephone();
          this.formatPharmacyTelephone();
          this.updatePharmacyInfo();
          this.updatePharmacyOwnerAndContact();
          this.$emit("updateStep", 2);
        }
      }
    },
    updatePharmacyInfo: function () {
      if (!this.pharmacy.commercial_name)
        this.pharmacy.commercial_name = this.pharmacyOwner.full_name;
      if (this.pharmacy.billing_match_data) {
        this.pharmacy.billing_address = this.pharmacy.address;
        this.pharmacy.fiscal_name = this.pharmacy.commercial_name;
      }
      this.pharmacy.soe = this.pharmacy.soe || null;
      if (this.others_programs) {
        this.pharmacy.computer_programs = null;
        this.pharmacy.othersComputerPrograms =
          this.pharmacy.othersComputerPrograms || null;
      } else {
        this.pharmacy.computer_programs =
          this.pharmacy.computer_programs || null;
      }
      this.$emit("updatePharmacyInfo", this.pharmacy);
    },
    updatePharmacyOwnerAndContact: function () {
      this.$emit("updatePharmacyOwnerAndContact", {
        pharmacyOwner: this.pharmacyOwner,
        pharmacyContact: this.contact ? this.pharmacyContact : null,
      });
    },
    phone() {
      this.pharmacy.phone_number = this.country_code.concat(
        "",
        this.phone_number
      );
    },
    pharmacy_phone() {
      this.pharmacyOwner.phone_number = this.pharmacy_code.concat(
        "",
        this.pharmacy_phone_number
      );
    },
    contact_phone() {
      this.pharmacyContact.phone_number = this.contact_code.concat(
        "",
        this.contact_phone_number
      );
    },
    getAddressData(addressData, placeResultData) {
      this.pharmacy.address = {
        raw: addressData.name,
        route: addressData.route,
        street_number: addressData.street_number,
        formatted: placeResultData.formatted_address,
        latitude: addressData.latitude,
        longitude: addressData.longitude,
        locality: {
          postal_code: addressData.postal_code,
          name: addressData.locality,
          state: {
            name: placeResultData.address_components.find(
              (elm) => elm.short_name == addressData.administrative_area_level_2
            ).long_name,
            code:
              placeResultData.address_components.find(
                (elm) => elm.long_name == addressData.country
              ).short_name +
              "-" +
              addressData.administrative_area_level_2,
            country: {
              name: addressData.country,
              code: placeResultData.address_components.find(
                (elm) => elm.long_name == addressData.country
              ).short_name,
            },
          },
        },
      };
    },
    getBillingAddressData(addressData, placeResultData) {
      this.pharmacy.billing_address = {
        raw: addressData.name,
        route: addressData.route,
        street_number: addressData.street_number,
        formatted: placeResultData.formatted_address,
        latitude: addressData.latitude,
        longitude: addressData.longitude,
        locality: {
          postal_code: addressData.postal_code,
          name: addressData.locality,
          state: {
            name: placeResultData.address_components.find(
              (elm) => elm.short_name == addressData.administrative_area_level_2
            ).long_name,
            code:
              placeResultData.address_components.find(
                (elm) => elm.long_name == addressData.country
              ).short_name +
              "-" +
              addressData.administrative_area_level_2,
            country: {
              name: addressData.country,
              code: placeResultData.address_components.find(
                (elm) => elm.long_name == addressData.country
              ).short_name,
            },
          },
        },
      };
    },
    formatOwnerTelephone() {
      if (this.pharmacyOwner.phone_number) {
        let phone = this.pharmacyOwner.phone_number;
        if (phone.startsWith("+")) {
          let tel = this.pharmacyOwner.phone_number;
          tel = tel.split(" ");
          let country = tel[0];
          tel.shift();
          let phone = tel.join();
          let phone2 = phone.replaceAll(",", "");
          this.pharmacyOwner.phone_number = country + " " + phone2;
        } else {
          let tel = this.country1 + this.pharmacyOwner.phone_number;
          tel = tel.split(" ");
          let country = tel[0];
          tel.shift();
          let phone = tel.join();
          let phone2 = phone.replaceAll(",", "");
          this.pharmacyOwner.phone_number = country + " " + phone2;
        }
      }
    },
    formatContactTelephone() {
      if (this.pharmacyContact.phone_number != null) {
        let phone = this.pharmacyContact.phone_number;
        if (phone.startsWith("+")) {
          let tel = this.pharmacyContact.phone_number;
          tel = tel.split(" ");
          let country = tel[0];
          tel.shift();
          let phone = tel.join();
          let phone2 = phone.replaceAll(",", "");
          this.pharmacyContact.phone_number = country + " " + phone2;
        } else {
          let tel = this.country2 + this.pharmacyContact.phone_number;
          tel = tel.split(" ");
          let country = tel[0];
          tel.shift();
          let phone = tel.join();
          let phone2 = phone.replaceAll(",", "");
          this.pharmacyContact.phone_number = country + " " + phone2;
        }
      }
    },
    formatPharmacyTelephone() {
      if (this.pharmacy.phone_number) {
        let phone = this.pharmacy.phone_number;
        if (phone.startsWith("+")) {
          let tel = this.pharmacy.phone_number;
          tel = tel.split(" ");
          let country = tel[0];
          tel.shift();
          let phone = tel.join();
          let phone2 = phone.replaceAll(",", "");
          this.pharmacy.phone_number = country + " " + phone2;
        } else {
          let tel = this.country3 + this.pharmacy.phone_number;
          tel = tel.split(" ");
          let country = tel[0];
          tel.shift();
          let phone = tel.join();
          let phone2 = phone.replaceAll(",", "");
          this.pharmacy.phone_number = country + " " + phone2;
        }
      }
    },
  },
};
</script>
<style scoped>
.custom-placeholer-color input,
.custom-label-color input {
  color: red !important;
}
</style>
