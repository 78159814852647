<template>
  <div class="pa-0 ma-0">
    <info-card :show="showCard" v-if="step == 0" />
    <v-container fluid class="pa-0 ma-0">
      <div v-if="step != 0">
        <register-layout
          @updateStep="updateSteps"
          :step="step"
          :steps="user.type == candidateUserType ? 3 : 2"
        />
      </div>
      <div v-else class="pa-0 ma-0">
        <main-layout v-if="isMobile" />
        <register @createdAccount="createdAccount" :validated="true" />
      </div>
      <v-row justify="center"
        ><v-col cols="12" sm="8" md="4">
          <span
            v-if="step != 0"
            class="primary--text font-weight-light text-caption mt-1 pl-md-0 pl-sm-2 pl-6"
            >{{ $t("register.step") + step + $t("register.of") }}
            {{ user.type == candidateUserType ? 2 : 3 }}</span
          >
        </v-col></v-row
      >
      <div v-if="isCandidateRegister">
        <div v-show="step == 1">
          <register-candidate-1
            @updateStep="updateSteps"
            @updateCandidateInfo="updateCandidateInfo"
          />
        </div>
        <div v-show="step == 2">
          <register-candidate-2
            @updateStep="updateSteps"
            @updateCandidateInfo="updateStudiesInfo"
          />
        </div>
        <div v-show="step == 3">
          <register-candidate-3
            @createRegister="createRegister"
            @updateCandidateInfo="updateCandidateInfo"
            :address="candidate.address"
          />
        </div>
      </div>
      <div v-if="isPharmacyRegister">
        <div v-show="step == 1">
          <register-pharmacy-1
            @updateStep="updateSteps"
            @updatePharmacyInfo="updatePharmacyInfo"
            @updatePharmacyOwnerAndContact="updatePharmacyOwnerAndContact"
          />
        </div>
        <div v-show="step == 2">
          <register-pharmacy-2
            @createRegister="createRegister"
            @updatePharmacyInfo="updatePharmacyInfo"
          />
        </div>
      </div>
    </v-container>
  </div>
</template>
<script>
// Layouts
import RegisterLayout from "@/components/layouts/Register.vue";
import MainLayout from "@/components/layouts/Main.vue";

// API
// import CandidateService from "@/services/CandidateService.js";

//modals
import InfoCard from "@/components/register/pharmacy/ValidatedInfoCard.vue";

// Register Main page
import Register from "@/components/register/Register.vue";

//candidate register components
import RegisterCandidate1 from "@/components/register/candidate/RegisterCandidate1.vue";
import RegisterCandidate2 from "@/components/register/candidate/RegisterCandidate2.vue";
import RegisterCandidate3 from "@/components/register/candidate/RegisterCandidate3.vue";

//Pharmacy register components
import RegisterPharmacy1 from "@/components/register/pharmacy/RegisterPharmacy1.vue";
import RegisterPharmacy2 from "@/components/register/pharmacy/RegisterPharmacy2.vue";
import _ from "lodash";

// import { mapGetters, mapActions } from "vuex";

// Configuration
import config from "@/config";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    Register,
    RegisterLayout,
    MainLayout,
    RegisterCandidate1,
    RegisterCandidate2,
    RegisterCandidate3,
    RegisterPharmacy1,
    RegisterPharmacy2,
    InfoCard,
  },
  data() {
    return {
      step: 0,
      user: {
        email: null,
        password: null,
        type: null,
      },
      showCard: false,
      candidate: {
        referrer_name: '',
        avatar: null,
        // experience_time: null,
        title: null,
        phone_number: null,
        birthday_date: null,
        curriculum: null,
        video: null,
        is_only_state: false,
        accept_terms_conditions: false,
        accept_privacy_policy: false,
        experience_fields: [],
        candidate_languages: [],
        working_day: null,
        active_search: true,
        pharmacy_experience: null,
        educations: [],
        expertises: [],
        user: {},
        profile: {},
        address: {
          raw: null,
          locality: {
            postal_code: null,
            name: null,
            state: {
              name: null,
              code: null,
              country: {
                name: null,
                code: null,
              },
            },
          },
        },
        gender: {
          id: null,
          name: null,
        },
      },
      pharmacyOwner: {
        full_name: null,
        phone_number: null,
        pharmacy: null,
      },
      pharmacyContact: {
        full_name: null,
        phone_number: null,
        email: null,
        pharmacy: null,
      },
      pharmacy: {
        user: {},
        video: null,
        address: {
          raw: null,
          route: null,
          street_number: null,
          formatted: null,
          latitude: null,
          longitude: null,
          locality: {
            postal_code: null,
            name: null,
            state: {
              name: null,
              code: null,
              country: {
                name: null,
                code: null,
              },
            },
          },
        },
        billing_address: {
          raw: null,
          route: null,
          street_number: null,
          formatted: null,
          latitude: null,
          longitude: null,
          locality: {
            postal_code: null,
            name: null,
            state: {
              name: null,
              code: null,
              country: {
                name: null,
                code: null,
              },
            },
          },
        },
        open_hour: {},
        number: null,
        commercial_name: null,
        fiscal_name: null,
        phone_number: null,
        web: null,
        employees_number: null,
        billing_match_data: false,
        accept_terms_conditions: false,
        accept_privacy_policy: false,
        nif: null,
        soe: null,
        logo: null,
      },
    };
  },
  computed: {
    ...mapGetters({ info: "user/info" }),
    ...mapGetters({ init_user_type: "user/init_user_type" }),
    candidateUserType() {
      return config.CONSTANTS.CANDIDATE_USER_TYPE;
    },
    pharmacyUserType() {
      return config.CONSTANTS.PHARMACY_USER_TYPE;
    },
    isCandidateRegister() {
      return (
        this.step >= 1 && this.info.init_user_type == this.candidateUserType
      );
    },
    isPharmacyRegister() {
      return (
        this.step >= 1 && this.info.init_user_type == this.pharmacyUserType
      );
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    ...mapActions({ enableLoading: "application/enableLoading" }),
    ...mapActions({ disableLoading: "application/disableLoading" }),
    ...mapActions({ registerCandidate: "user/registerCandidate" }),
    ...mapActions({ registerPharmacy: "user/registerPharmacy" }),
    updateSteps(data) {
      this.step = data;
      window.scrollTo(0, 0);
    },
    updatePharmacyInfo(data) {
      Object.assign(this.pharmacy, data);
      if (this.step == 1 && !this.pharmacy.billing_match_data) {
        Object.assign(this.pharmacy.billing_address, this.pharmacy.address);
        this.pharmacy.fiscal_name = this.pharmacy.commercial_name;
      }
    },
    updatePharmacyOwnerAndContact(data) {
      Object.assign(this.pharmacyOwner, data.pharmacyOwner);
      Object.assign(this.pharmacyContact, data.pharmacyContact);
    },
    updateStudiesInfo(data) {
      // this.candidate.experience_time = data.title.time;
      this.candidate.title = data.title;
      this.candidate.working_day = data.working_day;
      Object.assign(
        this.candidate.educations,
        data.educations.filter((elm) => elm.title != "")
      );
      Object.assign(
        this.candidate.expertises,
        data.expertises.length > 0 ? data.expertises : []
      );
      this.candidate.computer_programs = _.cloneDeep(data.computer_programs);
      this.candidate.othersComputerPrograms = _.cloneDeep(
        data.othersComputerPrograms
      );
      Object.assign(
        this.candidate.computer_programs,
        data.computer_programs.length > 0 ? data.computer_programs : []
      );
      Object.assign(
        this.candidate.othersComputerPrograms,
        data.othersComputerPrograms.length > 0
          ? data.othersComputerPrograms
          : []
      );
      Object.assign(
        this.candidate.experience_fields,
        data.experience_fields.length > 0 ? data.experience_fields : []
      );
      Object.assign(
        this.candidate.candidate_languages,
        data.candidate_languages
      );
      Object.assign(this.candidate.profile, data.profile);

      this.candidate.pharmacy_experience =
        data.pharmacy_experience == null ? false : data.pharmacy_experience;
    },
    async createRegister() {
      var response;
      try {
        this.enableLoading();
        if (this.info.init_user_type == this.pharmacyUserType) {
          this.pharmacy.user = this.info.id;
          response = await this.registerPharmacy({
            pharmacy: this.pharmacy,
            pharmacyOwner: this.pharmacyOwner,
            pharmacyContact: this.pharmacyContact,
            user: this.user,
          });
          this.finishRegister(response);
        } else if (this.info.init_user_type == this.candidateUserType) {
          console.log(this.candidate)
          this.candidate.user = this.info.id;
          response = await this.registerCandidate({
            candidate: this.candidate,
            user: this.user,
          });
          this.finishRegister(response);
          // }
        }
      } catch (error) {
        Object.values(error.response.data).forEach((elm) => {
          this.$notify({
            title: this.$i18n.tc("account.register_error", 1),
            text: elm.join(","),
            type: "error",
          });
        });
      } finally {
        this.disableLoading();
      }
    },
    async finishRegister(response) {
      await this.$store.dispatch(
        "user/updateUserPreferencesLanguage",
        this.$i18n.locale
      );
      if (response.status == 200) {
        await this.$acl.change(this.info.init_user_type.toLowerCase());
        this.$router.push({
          name: "offers",
          params: { initialModal: this.info.init_user_type },
        });
      }
    },

    async createdAccount(user) {
      try {
        let status = this.info.email
          ? 200
          : await this.$store.dispatch("user/login", user);
        if (this.info.user_type != "default")
          this.$router.push({ name: "offers" });
        if (status == 200) {
          this.user = user;
          this.step = 1;
        }
      } catch (error) {
        Object.values(error.response.data).forEach((elm) => {
          this.$notify({
            title: this.$i18n.tc("account.register_error", 1),
            text: Array.isArray(elm) ? elm.join(", ") : elm,
            type: "error",
          });
        });
      }
    },
    updateCandidateInfo(data) {
      Object.assign(this.candidate, data);
    },
    formatDate(date) {
      return date.split("/").reverse().join("-");
    },
    redirectRegister() {
      this.$router.push({ name: "login-desktop" });
    },
    redirectChangePassword() {
      this.$router.push({ name: "forgot-password" });
    },
    redirectContact() {
      this.$router.push({ name: "contact" });
    },
    redirectFaqs() {
      this.$router.push({ name: "faqs" });
    },
  },
  created() {
    setTimeout(() => {
      if (this.step == 0) this.showCard = true;
    }, 200);
  },
};
</script>
<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: #718f94;
}
.v-text-field--outlined fieldset {
  border-color: #718f94 !important;
}
.v-btn border {
  border-color: #718f94;
}
.background-image {
  background-image: url("~@/assets/apotalent/loginapo.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 1000px;
  padding-top: 0px !important;
  border: 0px !important;
  margin: 0px !important;
  background-position: top center;
}
.title-styles {
  font-size: 45px;
}
.title-body {
  font-size: 25px;
}
.title-heigth {
  height: 5%;
}
.body-height {
  height: 40%;
}
.wrap-login {
  border: 3px;
  background: white;
}
.bg-green-footer {
  background: #9ab4b8;
}
.height-row-1 {
  height: 73% !important;
}
.height-row-2 {
  height: 27% !important;
}
.wrap-width {
  width: 50%;
  border-right: solid #9ab4b8 1px;
}
.wrap-text {
  width: 60%;
}
.title-selection {
  color: black;
  font-size: 24px;
  font-weight: bold;
}
.text-selection {
  font-size: 20px;
}
.footer-options {
  margin-left: 40px;
  margin-right: 40px;
  font-size: 18px;
}
.title-action {
  color: black;
  font-size: 19px;
  font-weight: bold;
}
.wrap-under {
  text-decoration: underline;
}
.wrap-languages {
  width: 200px;
}

.footer-options .v-input {
  font-size: 18px !important;
}
.footer-options {
  border: 0px !important;
}
.footer-options .v-text-field > .v-input__control > .v-input__slot::before {
  border-style: none !important;
}
.v-text-field--outlined >>> fieldset {
  border-color: #718f94;
}
</style>
