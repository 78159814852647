<template>
  <v-row class="pa-0 ma-0" justify-sm="center">
    <v-col cols="12" sm="6" md="6" class="pa-0">
      <v-expand-transition>
        <v-card
          v-show="show"
          class="pa-0 ma-0"
          style="background: white; box-shadow: none"
          justify-sm="center"
        >
          <v-row class="pa-0 ma-0" justify-sm="center">
            <v-col cols="1" class="pa-0 pt-3 text-center">
              <v-icon color="secondary" class="mt-n2 ml-2"
                >mdi-information</v-icon
              ></v-col
            >
            <v-col cols="11" class="pa-0">
              <h4 class="my-2 pl-2 text-caption primary--text font-weight-bold" style="color: #FF0000 !important">
                {{ $t("register.subtitle") }}
              </h4></v-col
            >
          </v-row>
        </v-card>
      </v-expand-transition>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: ["show"],
};
</script>
